import React from 'react'
import {useNavigate} from 'react-router-dom'
import globalStyle from '../assets/styling'

import {SettingDropDown, ToggleSwitch} from './index'

import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {getOrders} from '../redux/actions/orders';

const close = require("../assets/img/close.png")
const pkg = require('../../package.json');

function Settings(props) {
    const navigate = useNavigate()

    if (!localStorage.getItem('deviceId')) {
        // Set defaults.
        localStorage.setItem('deviceId', '1')
        localStorage.setItem('showAllOrders', 'false')
        localStorage.setItem('updateStatusPerProduct', 'false')
        localStorage.setItem('updateInterval', '10')
        localStorage.setItem('horizontalItems', '4')
        localStorage.setItem('verticalItems', '2')
        localStorage.setItem('waitTime', '20')
        localStorage.setItem('nextOrder', 'completed')
    }

    function optionsArray() {
        return Array.from({length: 9}, (_, i) => String(i + 1))
    }

    return (
        <div style={styles.container}>
            <div style={styles.opacityContainer}>
            </div>
            <div style={styles.contentContainer}>
                <div style={styles.header}>
                    <h1 style={styles.headerText}>Instellingen (v{pkg.version})</h1>
                    <button style={globalStyle.button} onClick={() => {
                        props.getOrders();
                        props.closeSettings()
                    }}>
                        <img style={styles.icon} src={close}/>
                    </button>
                </div>

                <SettingDropDown settingsKey='deviceId' items={optionsArray()} name='Scherm ID'/>
                <ToggleSwitch settingsKey='showAllOrders' name='Producten van alle schermen weergeven'/>
                <ToggleSwitch settingsKey='updateStatusPerProduct' name='Per product status kunnen updaten'/>
                <SettingDropDown settingsKey='updateInterval' items={['1', '3', '5', '7', '10']} name='Update interval (sec)'/>
                <SettingDropDown settingsKey='horizontalItems' items={optionsArray()} name='Aantal horizontale bestellingen'/>
                <SettingDropDown settingsKey='verticalItems' items={optionsArray()} name='Aantal verticale bestellingen'/>
                <SettingDropDown settingsKey='waitTime' items={['5', '10', '15', '20', '25', '30', '35', '40', '45']} name='Aantal minuten tot bestelling rood kleurt'/>
                <SettingDropDown settingsKey='nextOrder' items={['ready', 'completed']} name='Volgende status van bestelling'/>

                <button style={styles.settingsButton} onClick={() => navigate('/apisettings')}>
                    <p style={{color: globalStyle.textColor}}>API instellingen</p>
                </button>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({getOrders}, dispatch)
const mapStateToProps = (state) => ({
    orders: state.orderReducers.orders
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)

const styles = {
    container: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    opacityContainer: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: 'rgba(241, 242, 247, 0.5)',

    },
    contentContainer: {
        position: 'fixed',
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'flex-start',
        margin: 'auto',
        width: 400,
        backgroundColor: globalStyle.secondaryBackgroundColor,
        zIndex: 5,
        padding: 24,
        borderRadius: 5,
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    headerText: {
        fontSize: 24,
        margin: 0,
        color: globalStyle.textColor
    },
    icon: {
        width: 24,
        height: 24,
        marginLeft: 10
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    settingsButton: {
        ...globalStyle.button,
        backgroundColor: globalStyle.backgroundColor,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
        marginTop: 24
    },
    settingContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: globalStyle.backgroundColor,
        padding: 5,
        borderRadius: 5,
        marginBottom: 5,
        marginTop: 10,
    },
    settingsName: {
        margin: 0,
        color: globalStyle.textColor
    },
    input: {
        color: '#000000',
        borderRadius: 5,
        border: 'none',
        paddingLeft: 8,
        backgroundColor: "#ffffff",
        width: 24,
        height: 24,
        fontFamily: globalStyle.bodyFontFamilyLight,
        outline: 'none'
    }
}

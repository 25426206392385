import React, {useState} from 'react'
import globalStyle from '../assets/styling'

export const SettingDropDown = (props) => {
    const [value, updateValue] = useState(localStorage.getItem(props.settingsKey));

    function renderSelector() {
        let options = []
        props.items.forEach((s, i) => {
            options.push(
                <option key={i} value={s} style={styles.option}>{s}</option>
            )
        })
        return (<select value={value} onChange={(e) => {
                localStorage.setItem(props.settingsKey, e.target.value);
                updateValue(e.target.value)
            }} style={styles.selector}>{options}</select>
        )
    }

    return (
        <div style={styles.container}>
            <p style={styles.settingsName}>{props.name}</p>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {renderSelector()}
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: globalStyle.backgroundColor,
        padding: 5,
        borderRadius: 5,
        marginBottom: 5,
        marginTop: 10,
        alignItems: 'center'
    },
    selector: {
        border: 'none',
        ...globalStyle.button
    },
    option: {
        color: globalStyle.textColor,
        fontSize: 20,
        ...globalStyle.button
    },
    settingsName: {
        color: globalStyle.textColor,
        margin: 0
    },
    icon: {
        width: 24,
        height: 24,
        marginLeft: 10
    },
}

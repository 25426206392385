import React, {useEffect} from 'react';
import {Order} from "../components";

export const OrderOverview = (props) => {
    useEffect(() => {

    }, [props.filters])

    function render() {
        let items = []
        let renderAllOrders = (localStorage.getItem('showAllOrders') === 'true')
        let deviceId = localStorage.getItem('deviceId')
        if (props.orders) {
            for (let j = 0; j < props.orders.length; j++) {
                let order = {
                    createTime: props.orders[j].createTime,
                    id: props.orders[j].id,
                    name: props.orders[j].name,
                    priority: props.orders[j].priority,
                    orderLines: [],
                    addedLater: []
                }
                if (renderAllOrders) {
                    for (let i = 0; i < props.orders[j].orderLines.length; i++) {
                        if (deviceId) {
                            if (props.filters.length > 0) {
                                if (props.filters.includes(props.orders[j].orderLines[i].status)) {
                                    order.orderLines.push(props.orders[j].orderLines[i])
                                }
                            } else {
                                order.orderLines.push(props.orders[j].orderLines[i])
                            }
                        }
                    }
                    if (props.orders[j].addedLater) {
                        for (let i = 0; i < props.orders[j].addedLater.length; i++) {
                            if (deviceId) {
                                if (props.filters.length > 0) {
                                    if (props.filters.includes(props.orders[j].addedLater[i].status)) {
                                        order.addedLater.push(props.orders[j].addedLater[i])
                                    }
                                } else {
                                    order.addedLater.push(props.orders[j].addedLater[i])
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < props.orders[j].orderLines.length; i++) {
                        if (deviceId) {
                            if (props.orders[j].orderLines[i].screenId === deviceId) {
                                if (props.filters.length > 0) {
                                    if (props.filters.includes(props.orders[j].orderLines[i].status)) {
                                        order.orderLines.push(props.orders[j].orderLines[i])
                                    }
                                } else {
                                    order.orderLines.push(props.orders[j].orderLines[i])
                                }
                            }
                        }
                    }
                    if (props.orders[j].addedLater) {
                        for (let i = 0; i < props.orders[j].addedLater.length; i++) {
                            if (deviceId) {
                                if (props.orders[j].addedLater[i].screenId === deviceId) {
                                    if (props.filters.length > 0) {
                                        if (props.filters.includes(props.orders[j].addedLater[i].status)) {
                                            order.addedLater.push(props.orders[j].addedLater[i])
                                        }
                                    } else {
                                        order.addedLater.push(props.orders[j].addedLater[i])
                                    }
                                }
                            }
                        }
                    }
                }
                if (order.orderLines.length > 0 || order.addedLater.length > 0) {
                    items.push(order)
                }
            }
        }
        if (!items || items.length === 0) return null
        items.forEach((item, index) => { //Extra check om te voorkomen dat orders die volledig uit completed orderlines bestaan niet worden weergegeven
            let completed = true
            item.orderLines.forEach((ol) => {
                if (ol.status !== 'completed') {
                    completed = false
                }
            })
            if (item.addedLater) {
                item.addedLater.forEach(ol => {
                    if (ol.status !== 'completed') {
                        completed = false
                    }
                })
            }
            if (completed) {
                items.splice(index, 1);
            }
        })
        const updateStatusPerProduct = localStorage.getItem('updateStatusPerProduct') === 'true' || localStorage.getItem('updateStatusPerProduct') === null
        let horizontalItems = Number(localStorage.getItem('horizontalItems'))
        let verticalItems = Number(localStorage.getItem('verticalItems'))
        let renderItems = []
        let orderLength = items.length
        let item = 0
        let backlogWidth = (props.sidebarVisible.right) ? 350 : 64
        let sidebarWidth = (props.sidebarVisible.left) ? 220 : 62
        let width = (props.windowDimensions.width - backlogWidth - sidebarWidth - Number(48 * horizontalItems)) / horizontalItems
        width = Number(width.toFixed(0))
        let height = props.windowDimensions.height - (48 * verticalItems)
        height = height / verticalItems
        if (orderLength === 0) {
            return
        }
        for (let i = 0; i < verticalItems; i++) {
            let horizontalRenderItems = []
            for (let j = 0; j < horizontalItems; j++) {
                if (item === orderLength) {
                    break
                }
                horizontalRenderItems.push(
                    <Order width={width} height={height} filters={props.filters} closed={true} order={items[item]} key={item}
                           updateStatusPerProduct={updateStatusPerProduct}/>
                )
                item = item + 1
            }
            renderItems.push(
                <div key={i} style={{display: 'flex', flexDirection: 'row'}}>{horizontalRenderItems}</div>
            )
        }
        return renderItems
    }

    return (
        render()
    )
}

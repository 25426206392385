import axios from 'axios';
import {saveBearer} from './auth';

export class api {
    static async ensureAuthToken() {
        let tokenExpiryTime = localStorage.getItem('tokenExpirationDate')
        if (tokenExpiryTime) {
            let now = new Date()
            if (now.getTime() > new Date(tokenExpiryTime).getTime()) {
                let res = await saveBearer()
                if (!res) {
                    return alert('Kan geen verbinding maken met de authenticatie server. Check uw internetverbinding.')
                }
            }
        }
    }

    static getBaseUrl() {
        const useLocal = localStorage.getItem('useLocal')
        const posId = localStorage.getItem('posID')
        if (useLocal === 'true') {
            const localIP = localStorage.getItem('localIP')
            return `http://${localIP}:8080/loc/${posId}/`
        } else {
            const hostURL = localStorage.getItem('hostURL')
            if (posId) {
                return hostURL + posId + '/'
            }
        }
    }

    static getAuthHeaders() {
        const useLocal = localStorage.getItem('useLocal')
        if (useLocal === 'true') {
            const userPass = localStorage.getItem('userPass')
            const userIdentity = localStorage.getItem('userIdentity')
            return {
                'useridentity': userIdentity,
                'userpass': userPass,
                'Content-Type': 'application/json',
            }
        } else {
            const token = localStorage.getItem('token')
            const api_key = localStorage.getItem('apiKey')
            return {
                Authorization: `Bearer ${token}`,
                'x-api-key': api_key,
                'Content-Type': 'application/json',
            }
        }
    }

    /**
     * Make a request to the PovisAPI.
     * 
     * @param method Request method. E.g., 'get' or 'put'.
     * @param endpointUrl URL of the API endpoint. E.g., 'products' or 'products/1234-5678'
     * @param body Request body.
     * @returns {AxiosPromise<any>}
     */
    static async makeRequest(method, endpointUrl, body) {
        await this.ensureAuthToken()
        const baseUrl = this.getBaseUrl()
        const authHeaders = this.getAuthHeaders()
        return axios({method: method, url: baseUrl + endpointUrl, data: body, headers: authHeaders})
            .catch(error => {
                console.error(error)
                throw error
            })
    }

    static async getOrders() {
        console.log('orders ophalen')
        return this.makeRequest('get', 'kitchenorders?size=500', null)
            .then(res => res.data._embedded.kitchenOrders)
    }

    static async updateOrder(orderId, body) {
        return this.makeRequest('patch', `kitchenorders/${orderId}/updatestatus`, body)
    }

    static async prioritizeOrder(orderId, body) {
        return this.makeRequest('put', `kitchenorders/${orderId}`, body);
    }
}

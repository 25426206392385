import React from 'react';
import ReactDOM from "react-dom/client";
import {Routes,
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,Route} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import pkg from '../package.json'

import {APISettings, Home, Initializing} from './views'
import {Provider} from 'react-redux';
import store from './redux/store'

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        release: pkg.version,
        dsn: "https://b769ca783c304ef7b509a9c6666133d5@o1333792.ingest.sentry.io/6677753",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <Provider store={store}>
        <BrowserRouter basename="/kms">
            <SentryRoutes>
                <Route path='/' element={<Initializing/>}></Route>
                <Route path='/home' element={<Home/>}></Route>
                <Route path='/apisettings' element={<APISettings/>}></Route>
            </SentryRoutes>
        </BrowserRouter>
    </Provider>
);

import React, {useState} from 'react'
import {getOrders, restoreOrder} from "../redux/actions/orders";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'

import globalStyle from '../assets/styling'
import {CheckboxItem, Settings, Time} from './index';

const povisLogo = require('../assets/img/Povis-Logo-naked.png')
const undo = require('../assets/img/undo.png')
const settings = require('../assets/img/settings.png')
const arrow = require('../assets/img/arrow_down.png')
const filterIconIMG = require('../assets/img/filter.png')
const noWifi = require('../assets/img/no-wifi.png')

function Sidebar(props) {
    const [showSettings, updateShowSettings] = useState(false);
    const [showFilters, updateShowFilters] = useState(false)
    const [showFullSideBar, updateShowFullSideBar] = useState(true)

    if (!localStorage.getItem('deviceId') && !showSettings) {
        updateShowSettings(true)
    }

    function renderSettings() {
        if (showSettings) {
            return (<Settings closeSettings={() => updateShowSettings(false)}/>)
        }
    }

    function renderFilters() {
        let filterTypes = ['completed', 'new', 'ready', 'waiting']
        let filters = []
        filterTypes.forEach((f, i) => {
            let checked = false
            if (props.filters.includes(f)) {
                checked = true
            }
            filters.push(<CheckboxItem changeFilters={(f, t) => props.changeFilters(f, t)} name={f} key={i} checked={!checked}/>)
        })
        let filterText = []
        let filterIcon = []
        if (showFullSideBar) {
            filterText.push(<p key={1} style={styles.buttonText}>Filters</p>)
            filterIcon.push(
                <img key={2} style={styles.arrowSideways} src={arrow} alt=''/>
            )
        } else {
            filterIcon.push(
                <img key={1} style={styles.arrowSideways} src={filterIconIMG} alt=''/>
            )
        }
        if (showFilters) {
            return (
                <div style={styles.filterContainer}>
                    <button style={styles.filterContainerButton} onClick={() => updateShowFilters(!showFilters)}>
                        <img style={styles.arrowdown} src={arrow} alt=''/>
                        <p style={styles.buttonText}>Filters</p>
                    </button>
                    {filters}
                </div>
            )
        } else {
            return (
                <div>
                    <button style={styles.button} onClick={() => {
                        updateShowFilters(!showFilters);
                        updateShowFullSideBar(true)
                    }}>
                        {filterIcon}
                        {filterText}
                    </button>
                </div>
            )
        }
    }

    function renderOnline() {
        if (!props.online) {
            return (
                <div style={{
                    display: 'flex',
                    backgroundColor: '#FF6B6B',
                    borderRadius: 15,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <p style={{
                        margin: 0,
                        padding: 4, color: globalStyle.textColor
                    }}>Offline</p>
                </div>
            )
        }
    }

    function renderOnlineSidebar() {
        if (!props.online) {
            return (
                <img src={noWifi} style={{width: 30, height: 30, marginTop: 8}}/>
            )
        }
    }

    if (showFullSideBar) {
        return (
            <div>
                {renderSettings()}
                <div style={styles.sidebarContainer}>
                    <button style={{...globalStyle.button}} onClick={() => {
                        updateShowFullSideBar(false);
                        updateShowFilters(false);
                        props.sidebarVisible(false)
                    }}>
                        <img style={styles.moveSideBarIcon} alt={''} src={arrow}/>
                    </button>
                    <div>
                        <img style={styles.logo} src={povisLogo} alt={''}/>
                        {renderOnline()}
                        <Time small={false}/>
                    </div>
                    {renderFilters()}
                    <div style={styles.footerContainer}>
                        <button style={styles.button} onClick={() => {
                            props.restoreOrder();
                            props.getOrders();
                        }}>
                            <img style={styles.buttonIcon} src={undo} alt=''/>
                            <p style={styles.buttonText}>Haal bestelling terug</p>
                        </button>
                        <button style={styles.button} onClick={() => updateShowSettings(true)}>
                            <img style={styles.buttonIcon} src={settings} alt=''/>
                            <p style={styles.buttonText}>Instellingen</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                {renderSettings()}
                <div style={styles.sidebarContainerSmall}>
                    <div>
                        <button style={styles.expandButton} onClick={() => {
                            updateShowFullSideBar(!showFullSideBar);
                            updateShowFilters(false);
                            props.sidebarVisible(true)
                        }}>
                            <img style={{transform: 'rotate(270deg)', width: 24, height: 24, marginTop: 24, backgroundColor: globalStyle.secondaryBackgroundColor, padding: 5, borderRadius: 5}} src={arrow} alt={''}/>
                        </button>
                        <img style={styles.logoSmall} src={povisLogo} alt={''}/>
                        {renderOnlineSidebar()}
                        <Time small={true}/>
                    </div>
                    {renderFilters()}
                    <div style={styles.footerContainer}>
                        <button style={styles.button} onClick={() => props.restoreOrder()}>
                            <img style={styles.buttonIcon} src={undo} alt=''/>

                        </button>
                        <button style={styles.button} onClick={() => updateShowSettings(true)}>
                            <img style={styles.buttonIcon} src={settings} alt=''/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({restoreOrder, getOrders}, dispatch)
const mapStateToProps = (state) => ({
    online: state.orderReducers.online
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

const styles = {
    sidebarContainer: {
        backgroundColor: globalStyle.backgroundColor,
        width: 188,
        paddingLeft: 16,
        paddingRight: 16,
        height: '100vh',
        justifyContent: 'space-between',
        boxShadow: "5px 0 4px 0 rgba(0,0,0,0.14)"
    },
    sidebarContainerSmall: {
        backgroundColor: globalStyle.backgroundColor,
        width: 30,
        paddingLeft: 16,
        paddingRight: 16,
        height: '100vh',
        justifyContent: 'space-between',
        boxShadow: "5px 0 4px 0 rgba(0,0,0,0.14)"
    },
    logo: {
        marginTop: 24,
        alignSelf: 'center',
        width: 188
    },
    logoSmall: {
        marginTop: 24,
        alignSelf: 'center',
        width: 30
    },
    button: {
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        backgroundColor: globalStyle.secondaryBackgroundColor,
        paddingTop: 5,
        paddingBottom: 5,
        height: 32,
        borderRadius: 5,
        width: '100%',
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // boxShadow: "0 2px 4px 0 rgba(0,0,0,0.50)",
        boxShadow: "6px 6px 4px 0 rgba(0,0,0,0.13)",
    },
    expandButton: {
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
    },
    filterContainer: {
        backgroundColor: globalStyle.secondaryBackgroundColor,
        paddingBottom: 5,
        //boxShadow: "0 2px 4px 0 rgba(0,0,0,0.50)",
        boxShadow: "6px 6px 4px 0 rgba(0,0,0,0.13)",
    },
    buttonText: {
        margin: 0,
        color: globalStyle.textColor,

    },
    buttonIcon: {
        width: 16,
        height: 16,
        marginLeft: 8,
        marginRight: 8
    },
    arrowSideways: {
        width: 16,
        height: 16,
        marginLeft: 8,
        marginRight: 8,
        transform: 'rotate(270deg)'
    },
    arrowdown: {
        width: 16,
        height: 16,
        marginLeft: 8,
        marginRight: 8,
        marginTop: 0
    },
    filterContainerButton: {
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        backgroundColor: globalStyle.secondaryBackgroundColor,
        paddingTop: 5,
        paddingBottom: 5,
        height: 32,
        borderRadius: 5,
        width: '100%',
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    moveSideBarIcon: {
        position: 'absolute',
        width: 32,
        height: 32,
        transform: 'rotate(90deg)',
        left: 180,
        top: 4,
        backgroundColor: globalStyle.secondaryBackgroundColor,
        padding: 5,
        borderRadius: 5
    }
}

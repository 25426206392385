import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getOrders} from "../redux/actions/orders"
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'

import globalStyle from '../assets/styling'
import {saveBearer} from '../utils/auth';

const pkg = require('../../package.json');

function APISettings(props) {
    const navigate = useNavigate();
    const [showLocal, setShowLocal] = useState(true)
    const [posID, setPosID] = useState('')
    const [clientID, setClientID] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [apiKey, setApiKey] = useState('')
    const [hostURL, setHostURL] = useState('')
    const [userPass, setUserPass] = useState('')
    const [localIP, setLocalIP] = useState('')
    useEffect(() => {
        const useLocal = localStorage.getItem('useLocal') !== 'false'
        const posID = localStorage.getItem('posID')
        const clientID = localStorage.getItem('clientID')
        const clientSecret = localStorage.getItem('clientSecret')
        const apiKey = localStorage.getItem('apiKey')
        const hostURL = localStorage.getItem('hostURL') || 'https://api.povis.nl/v1/'
        const userPass = localStorage.getItem('userPass')
        const localIP = localStorage.getItem('localIP') || '192.168.x.x'

        setShowLocal(useLocal)
        if (posID) setPosID(posID)
        if (clientID) setClientID(clientID)
        if (clientSecret) setClientSecret(clientSecret)
        if (apiKey) setApiKey(apiKey)
        if (hostURL) setHostURL(hostURL)
        if (userPass) setUserPass(userPass)
        if (localIP) setLocalIP(localIP)
    }, [])
    const handleSave = async () => {
        if (showLocal) {
            localStorage.setItem('useLocal', 'true')
            localStorage.setItem('posID', 'auto')
            localStorage.setItem('userIdentity', 'PovisKMS')
            if (userPass) localStorage.setItem('userPass', userPass)
            if (localIP) localStorage.setItem('localIP', localIP)
        } else {
            localStorage.setItem('useLocal', 'false')
            if (posID) localStorage.setItem('posID', posID)
            if (clientID) localStorage.setItem('clientID', clientID)
            if (clientSecret) localStorage.setItem('clientSecret', clientSecret)
            if (apiKey) localStorage.setItem('apiKey', apiKey)
            if (hostURL) localStorage.setItem('hostURL', hostURL)
        }

        let bearer = await saveBearer()
        if (bearer) {
            props.getOrders()
            navigate('/')
        } else {
            alert('De instellingen voor internet kloppen niet.')
        }
    }

    function renderLocal() {
        return (
            <div>
                <div style={styles.warning}>Kassa moet v2+ zijn, lokale API v22+</div>
                <p style={styles.inputTypeText}>Wachtwoord</p>
                <input
                    value={userPass}
                    style={styles.input}
                    placeholder="KMS wachtwoord"
                    onChange={e => {setUserPass(e.target.value)}}
                />
                <p style={styles.inputTypeText}>POS IP-adres</p>
                <input
                    style={styles.input}
                    value={localIP}
                    placeholder="POS IP-adres"
                    onChange={e => {setLocalIP(e.target.value)}}
                />
            </div>
        )
    }

    function renderInternet() {
        if ('auto' === posID) setPosID('')
        return (
            <div>
                <div style={styles.warning}>Voeg '--disable-web-security' toe aan shortcut naar Chrome.</div>
                <p style={styles.inputTypeText}>POS Hostname</p>
                <input
                    style={styles.input}
                    placeholder="Pos ID"
                    value={posID}
                    onChange={e => {setPosID(e.target.value)}}
                    autoCapitalize="none"
                />
                <p style={styles.inputTypeText}>Client ID</p>
                <input
                    style={styles.input}
                    placeholder='Client ID'
                    value={clientID}
                    onChange={e => {setClientID(e.target.value)}}
                />
                <p style={styles.inputTypeText}>Client Secret</p>
                <input
                    style={styles.input}
                    placeholder="Client Secret"
                    value={clientSecret}
                    onChange={e => {setClientSecret(e.target.value)}}
                />
                <p style={styles.inputTypeText}>API Key</p>
                <input
                    style={styles.input}
                    value={apiKey}
                    placeholder="API Key"
                    onChange={e => {setApiKey(e.target.value)}}
                />
                <p style={styles.inputTypeText}>API URL</p>
                <input
                    value={hostURL}
                    style={styles.input}
                    placeholder="API URL"
                    onChange={e => {setHostURL(e.target.value)}}
                />
            </div>
        )
    }

    function renderButtons() {
        if (showLocal) {
            return (
                <div style={styles.buttonContainer}>
                    <button style={styles.activeButton}><p style={styles.activeButtonText}>Lokaal</p></button>
                    <button onClick={() => {setShowLocal(false)}} style={styles.inactiveButton}><p style={styles.inactiveButtonText}>Internet</p></button>
                </div>
            )
        } else {
            return (
                <div style={styles.buttonContainer}>
                    <button onClick={() => {setShowLocal(true)}} style={styles.inactiveButton}><p style={styles.inactiveButtonText}>Lokaal</p></button>
                    <button style={styles.activeButton}><p style={styles.activeButtonText}>Internet</p></button>
                </div>
            )
        }
    }

    function showInputs() {
        if (showLocal) {
            return renderLocal()
        } else {
            return renderInternet()
        }
    }

    return (
        <div style={styles.mainContainer}>
            {renderButtons()}
            <div style={styles.formContainer}>
                {showInputs()}
                <button style={styles.button} onClick={async () => await handleSave()}><p style={styles.buttonText}>Naar keukenscherm</p></button>
                <p style={styles.inputTypeText}>Versie {pkg.version}</p>
            </div>
        </div>
    )
}

const styles = {
    mainContainer: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        flex: 1,
        backgroundColor: globalStyle.backgroundColor,
        justifyContent: 'center',
        alignItems: 'center'
    },
    formContainer: {
        alignSelf: 'center',
        width: 300,
        alignItems: 'center',
        justifyContent: 'center',

    },
    logo: {
        alignSelf: 'center',
        width: 200,
        resizeMode: 'contain',
    },
    input: {
        marginTop: 16,
        color: '#000000',
        borderRadius: 5,
        border: 'none',
        paddingLeft: 16,
        backgroundColor: "#ffffff",
        width: 300,
        height: 48,
        fontFamily: globalStyle.bodyFontFamilyLight,
        outline: 'none'
    },
    button: {
        border: 'none',
        marginTop: 32,
        width: 318,
        height: 48,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: globalStyle.secondaryBackgroundColor,
        cursor: 'pointer',
    },
    buttonText: {
        fontFamily: globalStyle.bodyFontFamilyBold,
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center'
    },
    buttonContainer: {
        backgroundColor: globalStyle.secondaryBackgroundColor,
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: 318,
        height: 48,
        borderRadius: 5,
        marginLeft: 16
    },
    inactiveButton: {
        border: 'none',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: globalStyle.backgroundColor,
        height: 40,
        cursor: 'pointer',
    },
    activeButton: {
        border: 'none',
        height: 40,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: globalStyle.accentColor,
        cursor: 'pointer',
    },
    activeButtonText: {
        fontFamily: globalStyle.fontFamily,
        fontSize: 24,
        fontWeight: 400,
        margin: 0,
        textDecorationLine: 'underline'
    },
    inactiveButtonText: {
        margin: 0
    },
    inputTypeText: {
        margin: 0,
        marginTop: 16,
        textAlign: 'center'
    },
    warning: {
        padding: '12px 16px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: 1,
        margin: 16,
        fontSize: 16,
        backgroundColor: 'rgba(252, 248, 227, 1)',
        borderColor: 'rgba(177, 161, 129, 1)',
        color: 'rgba(138, 109, 59, 1)',
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({getOrders}, dispatch)
const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(APISettings)

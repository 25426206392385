import React, {useEffect, useState} from "react";
import globalStyle from "../assets/styling";
import {Backlog, OrderOverview, Sidebar} from "../components";
import '../index.css'
import {getOrders} from "../redux/actions/orders";
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import {useIsFirstRender} from '../hooks';

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {width, height};
}

function Home(props) {
    const isFirst = useIsFirstRender()
    const [filters, updateFilters] = useState([])
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [sidebarVisible, changeIsSidebarVisible] = useState({left: true, right: true})
    let intervalTime = localStorage.getItem('updateInterval')
    let interval
    if (intervalTime) {
        intervalTime = Number(intervalTime) * 1000
    } else {
        intervalTime = 10000
    }
    if (isFirst) {
        props.getOrders()
        interval = setInterval(() => {
            props.getOrders()
        }, intervalTime)
    }
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        let savedFilters = localStorage.getItem('filters')
        if (savedFilters) {
            updateFilters(savedFilters.split(','))
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(interval)
        }
    }, [])
    function changeFilters(f, t) {
        let newFilters
        if (t === 'add') {
            newFilters = [...filters, f]
            updateFilters([...filters, f])
        } else {
            newFilters = filters.filter(i => i !== f)
            updateFilters(filters.filter(i => i !== f))
        }
        localStorage.setItem('filters', newFilters)
    }

    return (
        <div style={styles.homeContainer}>
            <Sidebar changeFilters={(f, t) => changeFilters(f, t)} filters={filters} sidebarVisible={(v) => changeIsSidebarVisible({...sidebarVisible, left: v})}/>
            <div style={styles.orderContainer}>
                <OrderOverview orders={props.orders} filters={filters} sidebarVisible={sidebarVisible} windowDimensions={windowDimensions}/>
            </div>
            <Backlog filters={filters} orders={props.orders} sidebarVisible={(v) => changeIsSidebarVisible({...sidebarVisible, right: v})}/>
        </div>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({getOrders}, dispatch)
const mapStateToProps = (state) => ({
    orders: state.orderReducers.orders,
    restoredOrders: state.orderReducers.restoredOrders,
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)

const styles = {
    homeContainer: {
        display: 'flex',
        maxWidth: '100vw',
        height: '100vh',
        flexDirection: 'row',
        backgroundColor: globalStyle.secondaryBackgroundColor,
    },
    orderContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100vh'
    }
}

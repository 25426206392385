import React, {useState} from 'react'
import globalStyle from '../assets/styling'

const done = require("../assets/img/done.png")

export const SettingTextInput = (props) => {
    const [value, updateValue] = useState(props.value);
    const [save, updateSave] = useState(false)

    function update(value) {
        updateSave(true)
        updateValue(value)
    }

    function renderSaveButton() {
        if (save) {
            return (
                <button style={globalStyle.button} onClick={() => {
                    updateSave(false)
                    props.updateValue(value)
                }}>
                    <img style={styles.icon} src={done}/>
                </button>
            )
        }
    }

    return (
        <div style={styles.container}>
            <p style={styles.settingsName}>{props.name}</p>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <input
                    style={styles.input}
                    value={value}
                    type="number"
                    onChange={(e) => {
                        if (Number(e.target.value) < 1 && e.target.value !== '') {
                            update(1)
                        } else {
                            update(e.target.value)
                        }
                    }}/>
                {renderSaveButton()}
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: globalStyle.backgroundColor,
        padding: 5,
        borderRadius: 5,
        marginBottom: 5,
        marginTop: 10,
        alignItems: 'center'
    },
    input: {
        color: '#000000',
        borderRadius: 5,
        border: 'none',
        paddingLeft: 8,
        backgroundColor: "#ffffff",
        width: 35,
        height: 24,
        fontFamily: globalStyle.bodyFontFamilyLight,
        outline: 'none'
    },
    settingsName: {
        color: globalStyle.textColor,
        margin: 0
    },
    icon: {
        width: 24,
        height: 24,
        marginLeft: 10
    },
}

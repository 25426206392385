import React, {useState} from 'react'
import globalStyle from '../assets/styling'

export const ToggleSwitch = (props) => {
    const [toggled, updateToggled] = useState(localStorage.getItem(props.settingsKey) === 'true')

    function update() {
        updateToggled(!toggled)
        localStorage.setItem(props.settingsKey, !toggled ? 'true' : 'false');
    }

    function buttonStyles() {
        if (toggled) {
            return ({
                backgroundColor: '#00B612',
                width: 30,
                height: 25,
                borderRadius: 15,
                border: 'none'
            })
        } else {
            return ({
                backgroundColor: '#ff0500',
                width: 30,
                height: 25,
                borderRadius: 15,
                border: 'none',
                marginLeft: 25
            })
        }
    }

    return (
        <div style={styles.container}>
            <p style={styles.settingsName}>{props.name}</p>
            <button style={styles.input} onClick={() => update()}>
                <div style={buttonStyles()}/>
            </button>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: globalStyle.backgroundColor,
        padding: 5,
        borderRadius: 5,
        marginBottom: 5,
        marginTop: 10,
        alignItems: 'center'
    },
    name: {
        color: '#000000',
        borderRadius: 5,
        border: 'none',
        paddingLeft: 8,
        backgroundColor: "#ffffff",
        width: 24,
        height: 24,
        fontFamily: globalStyle.bodyFontFamilyLight,
        outline: 'none'
    },
    input: {
        ...globalStyle.button,
        borderRadius: 15,
        width: 55,
        height: 25,
        backgroundColor: globalStyle.secondaryBackgroundColor
    },
    settingsName: {
        color: globalStyle.textColor,
        margin: 0
    }
}

import React, {useEffect, useState} from 'react'
import {Order} from './'

import globalStyle from '../assets/styling'

const arrow = require('../assets/img/arrow_down.png')

export function Backlog(props) {
    useEffect(() => {

    }, [props.filters])
    const [showFullBacklog, updateShowFullBacklog] = useState(true)
    const [openOrder, updateOpenOrder] = useState(-1) // Set when a small order is clicked to expand it automatically.
    function makeItems(orders) {
        let items = []
        let renderAllOrders = (localStorage.getItem('showAllOrders') === 'true')
        let deviceId = localStorage.getItem('deviceId')
        if (orders) {
            for (let j = 0; j < orders.length; j++) {
                let order = {
                    createTime: orders[j].createTime,
                    id: orders[j].id,
                    name: orders[j].name,
                    priority: orders[j].priority,
                    orderLines: [],
                    addedLater: []
                }
                if (renderAllOrders) {
                    for (let i = 0; i < orders[j].orderLines.length; i++) {
                        if (deviceId) {
                            if (props.filters.length > 0) {
                                if (props.filters.includes(orders[j].orderLines[i].status)) {
                                    order.orderLines.push(orders[j].orderLines[i])
                                }
                            } else {
                                order.orderLines.push(orders[j].orderLines[i])
                            }
                        }
                    }
                    if (orders[j].addedLater) {
                        for (let i = 0; i < orders[j].addedLater.length; i++) {
                            if (deviceId) {
                                if (props.filters.length > 0) {
                                    if (props.filters.includes(orders[j].addedLater[i].status)) {
                                        order.addedLater.push(orders[j].addedLater[i])
                                    }
                                } else {
                                    order.addedLater.push(orders[j].addedLater[i])
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < orders[j].orderLines.length; i++) {
                        if (deviceId) {
                            if (orders[j].orderLines[i].screenId === deviceId) {
                                if (props.filters.length > 0) {
                                    if (props.filters.includes(orders[j].orderLines[i].status)) {
                                        order.orderLines.push(orders[j].orderLines[i])
                                    }
                                } else {
                                    order.orderLines.push(orders[j].orderLines[i])
                                }
                            }
                        }
                    }
                    if (orders[j].addedLater) {
                        for (let i = 0; i < orders[j].addedLater.length; i++) {
                            if (deviceId) {
                                if (orders[j].addedLater[i].screenId === deviceId) {
                                    if (props.filters.length > 0) {
                                        if (props.filters.includes(orders[j].addedLater[i].status)) {
                                            order.addedLater.push(orders[j].addedLater[i])
                                        }
                                    } else {
                                        order.addedLater.push(orders[j].addedLater[i])
                                    }
                                }
                            }
                        }
                    }
                }
                if (order.orderLines.length > 0 || order.addedLater.length > 0) {
                    items.push(order)
                }
            }
        }
        return items
    }

    function renderOrders() {
        let orderItems = makeItems(props.orders)
        let renderItems = []
        const updateStatusPerProduct = localStorage.getItem('updateStatusPerProduct') === 'true' || localStorage.getItem('updateStatusPerProduct') === null
        let horizontalItems = Number(localStorage.getItem('horizontalItems'))
        let verticalItems = Number(localStorage.getItem('verticalItems'))
        let items = horizontalItems * verticalItems
        let size = 0
        for (let i = 0; i < orderItems.length; i++) {
            if (!orderItems[i].completed) {
                size = size + 1
                if (items && size > items) {
                    renderItems.push(<Order closed={i === openOrder} filters={props.filters} order={orderItems[i]} key={i}
                                            backgroundColor={globalStyle.secondaryBackgroundColor} updateStatusPerProduct={updateStatusPerProduct}/>)
                }
            }
        }
        return renderItems
    }

    function renderSmallOrders() {
        let orderItems = makeItems(props.orders)
        let renderItems = []
        let size = 0
        let horizontalItems = Number(localStorage.getItem('horizontalItems'))
        let verticalItems = Number(localStorage.getItem('verticalItems'))
        let items = horizontalItems * verticalItems
        for (let i = 0; i < orderItems.length; i++) {
            if (!orderItems[i].completed) {
                size = size + 1
                if (items && size > items) {
                    renderItems.push(
                        <div style={styles.smallItemContainer} onClick={() => {
                            updateShowFullBacklog(true);
                            props.sidebarVisible(true);
                            updateOpenOrder(i);
                        }}>
                            <p style={styles.smallTableName}>{orderItems[i].name}</p>
                        </div>
                    )
                }
            }
        }
        return renderItems
    }

    function renderShowHideIcon() {
        return (
            <button style={globalStyle.button} onClick={() => {
                updateShowFullBacklog(!showFullBacklog);
                updateOpenOrder(-1);
                props.sidebarVisible(!showFullBacklog)
            }}>
                <img src={arrow} style={iconStyles()} alt={''}/>
            </button>
        )
    }

    function iconStyles() {
        if (showFullBacklog) {
            return ({
                position: 'absolute',
                width: 32, height: 32, transform: 'rotate(270deg)', top: 4, right: 330,
                padding: 8,
                backgroundColor: globalStyle.secondaryBackgroundColor, borderRadius: 5
            })
        } else {
            return ({
                width: 32, height: 32, transform: 'rotate(90deg)', marginTop: 24,
                padding: 8,
                backgroundColor: globalStyle.secondaryBackgroundColor, borderRadius: 5
            })
        }
    }

    if (showFullBacklog) {
        return (
            <div style={styles.sidebarContainer}>
                {renderShowHideIcon()}
                {renderOrders()}
            </div>
        )
    } else {
        return (
            <div style={styles.smallSidebarContainer}>
                {renderShowHideIcon()}
                {renderSmallOrders()}
            </div>
        )
    }
}

const styles = {
    sidebarContainer: {
        backgroundColor: globalStyle.backgroundColor,
        width: 350,
        paddingLeft: 8,
        paddingRight: 8,
        height: '100vh',
        overflowY: 'auto',
        boxShadow: "5px 5px 4px 5px rgba(0,0,0,0.14)"
    },
    smallSidebarContainer: {
        backgroundColor: globalStyle.backgroundColor,
        width: 48,
        paddingLeft: 8,
        paddingRight: 8,
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        boxShadow: "5px 5px 4px 5px rgba(0,0,0,0.14)"
    },
    smallItemContainer: {
        display: 'flex',
        backgroundColor: globalStyle.secondaryBackgroundColor,
        marginTop: 8,
        padding: 16,
        marginBottom: 8,
        boxShadow: "6px 6px 4px 0 rgba(0,0,0,0.13)",
        borderRadius: 5,
        height: 24,
        cursor: 'pointer',
        alignItems: 'center'
    },
    smallTableName: {
        margin: 0,
        color: globalStyle.textColor
    },
}

import React, {useEffect} from 'react'
import globalStyle from '../assets/styling'
import {api} from '../utils/api'
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {completeOrder} from '../redux/actions/orders';

const done = require('../assets/img/sauce.png')
const notDone = require('../assets/img/arrows.png')
const ready = require('../assets/img/delivery.png')
const waitIcon = require('../assets/img/hourglass.png')
const noteIcon = require('../assets/img/notes.png')

function Product(props) {
    useEffect(() => {


    }, [props.product.status])

    async function updateStatus(newStatus) {
        if (!props.updateStatusPerProduct) {
            return
        }
        let order = {}
        props.orders.forEach(i => {
            if (i.id === props.orderId) {
                order = i
                let completed = true
                i.orderLines.forEach(ol => {
                    if (props.product.id !== ol.id) {
                        if (ol.status !== 'completed') {
                            completed = false
                        }
                    }
                })
                if (completed) {
                    props.completeOrder(order)
                }
            }
        })
        let body = [{
            id: props.product.id,
            status: newStatus
        }]
        if (props.product.complementaryProducts) {
            props.product.complementaryProducts.forEach(cp => {
                body.push(
                    {id: cp.id, status: newStatus}
                )
            })
        }
        body = JSON.stringify(body)

        api.updateOrder(props.orderId, body).then(() => props.getOrders())
    }

    function containerStyles() {
        if (props.disabled) {
            return ({
                display: 'flex',
                flexDirection: 'row',
                marginTop: 4,
                alignItems: 'center',
                backgroundColor: '#bebebe',
                borderRadius: 15,
            })
        } else {
            return ({
                display: 'flex',
                flexDirection: 'row',
                marginTop: 4,
                alignItems: 'center',
            })
        }
    }

    function renderIcon() {
        if (!props.updateStatusPerProduct) {
            return
        }
        if (props.product.status === 'new') {
            return (
                <img style={styles.icon} src={done} alt={'New'}/>
            )
        } else if (props.product.status === 'completed') {
            return (
                <img style={styles.icon} src={notDone} alt={'Completed'}/>
            )
        } else if (props.product.status === 'ready') {
            return (
                <img style={{...styles.icon, width: 24, height: 24}} src={ready} alt={'Ready'}/>
            )
        } else if (props.product.status === 'waiting') {
            return (
                <img style={{...styles.icon}} src={waitIcon} alt={'Waiting'}/>
            )
        }
    }

    const renderCompleted = () => {
        let complimentaryProductsRenderItems = []
        if (props.product.attributeValues) {
            if (props.product.attributeValues.length > 0) {
                complimentaryProductsRenderItems.push(
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 5, marginLeft: 25}}>
                        <p style={styles.cpText}>{props.product.attributeValues}</p>
                    </div>
                )
            }
        }
        let note = []
        if (props.product.note && props.product.note.length > 0) {
            note.push(
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: globalStyle.secondaryBackgroundColor, width: '100%', borderRadius: 5, padding: 4}}>
                    <img style={styles.notesIcon} src={noteIcon}/>
                    <p style={styles.note}>{props.product.note}</p>

                </div>
            )
        }
        props.product.complementaryProducts.forEach((p, i) => {
            complimentaryProductsRenderItems.push(
                <div key={i} style={styles.cpContainer}>
                    <div style={{...styles.units, marginLeft: 25}}>
                        <p style={styles.unitText}>{p.units}</p>
                    </div>
                    <p style={{...styles.cpText, textDecorationLine: (p.status === 'completed' || p.status === 'ready') ? 'line-through' : 'none',}}>{p.productName}</p>
                </div>
            )
        })
        if (props.disabled) {
            let img = []
            if (props.updateStatusPerProduct) {
                if (props.product.status === 'new') {
                    img.push(<img key={1} style={styles.icon} src={done} alt={'Completed'}/>)
                } else if (props.product.status === 'completed') {
                    img.push(<img key={1} style={styles.icon} src={notDone} alt={'Ready'}/>)
                } else if (props.product.status === 'ready') {
                    img.push(<img key={1} style={styles.icon} src={ready} alt={'Ready'}/>)
                } else if (props.product.status === 'waiting') {
                    img.push(<img key={1} style={styles.icon} src={waitIcon} alt={'Waiting'}/>)
                }
            }
            return (
                <div style={styles.nameContainer}>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                        <div style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p style={styles.unit}>{props.product.units}</p>
                                <p style={styles.name}>{props.product.productName}</p>
                            </div>
                            {img}
                        </div>
                        {complimentaryProductsRenderItems}
                        {note}
                    </div>
                </div>
            )
        } else if (props.product.status === 'new') {
            return (
                <div style={styles.nameContainer}>
                    <button style={{...styles.button, cursor: props.updateStatusPerProduct ? 'pointer' : 'default'}} onClick={async () => updateStatus('ready')} title={props.product.status}>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p style={styles.unit}>{props.product.units}</p>
                                <p style={styles.name}>{props.product.productName}</p>
                            </div>
                            {renderIcon()}
                        </div>
                        {complimentaryProductsRenderItems}
                        {note}
                    </button>
                </div>
            )
        } else if (props.product.status === 'completed') {
            return (
                <div style={styles.nameContainer}>
                    <button style={{...styles.button, cursor: props.updateStatusPerProduct ? 'pointer' : 'default'}} onClick={async () => updateStatus('waiting')} title={props.product.status}>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p style={styles.unit}>{props.product.units}</p>
                                <p style={styles.nameDone}>{props.product.productName}</p>
                            </div>
                            {renderIcon()}
                        </div>
                        {complimentaryProductsRenderItems}
                        {note}
                    </button>
                </div>
            )
        } else if (props.product.status === 'ready') {
            return (
                <div style={styles.nameContainer}>
                    <button style={{...styles.button, cursor: props.updateStatusPerProduct ? 'pointer' : 'default'}} onClick={async () => updateStatus('completed')} title={props.product.status}>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p style={styles.unit}>{props.product.units}</p>
                                <p style={styles.nameDone}>{props.product.productName}</p>
                            </div>
                            {renderIcon()}
                        </div>
                        {complimentaryProductsRenderItems}
                        {note}
                    </button>
                </div>
            )
        } else if (props.product.status === 'waiting') {
            return (
                <div style={styles.nameContainer}>
                    <button style={{...styles.button, cursor: props.updateStatusPerProduct ? 'pointer' : 'default'}} onClick={async () => updateStatus('new')} title={props.product.status}>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p style={styles.unit}>{props.product.units}</p>
                                <p style={styles.name}>{props.product.productName}</p>
                                {note}
                            </div>
                            {renderIcon()}
                        </div>
                        {complimentaryProductsRenderItems}
                    </button>
                </div>
            )
        }
    }
    return (
        <div style={containerStyles()}>
            {renderCompleted()}
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({completeOrder}, dispatch)
const mapStateToProps = (state) => ({
    orders: state.orderReducers.orders
})

export default connect(mapStateToProps, mapDispatchToProps)(Product)

const styles = {
    productContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 4,
        height: 20,
        alignItems: 'center',
    },
    units: {
        width: 20,
        height: 20,
        backgroundColor: globalStyle.secondaryBackgroundColor,
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    unitText: {
        textAlign: 'center',
        fontSize: 12,
        fontFamily: globalStyle.fontFamily,
        margin: 0,
    },
    name: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 8,
        color: globalStyle.textColor,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 20,
        textAlign: 'left',
        wordBreak: 'break-all'
    },
    nameDone: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 8,
        textDecorationLine: 'line-through',
        height: 20,
        overflow: 'hidden',
        color: globalStyle.textColor,
        textAlign: 'left',
        wordBreak: 'break-all'
    },
    nameContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 1
    },
    button: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
    },
    icon: {
        width: 20,
        height: 20
    },
    cpContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5
    },
    cpText: {
        margin: 0,
        textAlign: 'left',
        marginLeft: 5
    },
    unit: {
        backgroundColor: globalStyle.secondaryBackgroundColor,
        height: 20,
        margin: 0,
        textAlign: 'center',
        padding: 3,
        borderRadius: 25,
        fontSize: 16
    },
    note: {
        color: globalStyle.textColor,
        margin: 0,
        fontWeight: "600",
        textAlign: 'left'
    },
    notesIcon: {
        width: 15,
        height: 15, marginRight: 8
    }
}

import {api} from '../../utils/api'

export const orderActions = {
    getOrders: '@@orders/getOrders',
    completeOrder: '@@orders/completeOrder',
    restoreOrder: '@@orders/restoreOrder',
    bringForward: '@@orders/bringForward',
    setOffline: '@@orders/setOffline',
    setOnline: '@@orders/setOnline'
}

function filterContent(res) {
    let filterContent = []
    res.forEach(o => { //Orders waarvan alle producten op completed staan markeren als completed
        let completed = true
        o.orderLines.forEach(ol => {
            if (ol.productName === "XXX TAFELDOOR XXX" && ol.status !== "completed") {

                o.name = o.name.replace('Ch: ', '')
                o.continue = true
            }
            if (ol.status === "completed") {
            } else {
                completed = false
            }
        })
        if (completed) {
            o.completed = true
        }
    })
    res.forEach(o => { //Orders van een tafel samenvoegen
        if (!o.completed) {
            if (filterContent.length < 1) {
                filterContent.push(o)
            } else {
                if (filterContent.find(c => c.name === o.name) === undefined) {
                    filterContent.push(o)
                } else {
                    filterContent.forEach(fc => {
                        if (fc.name === o.name) {
                            if (fc.addedLater) {
                                if (o.continue) {
                                    fc.continue = true
                                }
                                o.orderLines.forEach(o => {
                                    fc.addedLater.push(o)
                                })
                            } else {
                                if (o.continue) {
                                    fc.continue = true
                                }
                                fc.addedLater = o.orderLines
                            }
                            if (o.priority) {
                                fc.priority = true
                            }
                        }
                    })
                }
            }
        }
    })
    let items = []
    filterContent.forEach(i => {
        if (i.continue || i.priority) {
            items = [i, ...items]
        } else {
            items.push(i)
        }
    })
    return items
}

export function getOrders() {
    return async dispatch => {
        api.getOrders().then(orders => {
            let items = filterContent(orders)
            dispatch({
                type: orderActions.setOnline
            })
            return dispatch({
                type: orderActions.getOrders,
                data: items
            })
        }).catch(() => dispatch({
            type: orderActions.setOffline,
        }))
    }
}


export function completeOrder(o) {
    return dispatch => {
        return dispatch({
            type: orderActions.completeOrder,
            data: o
        })
    }
}

export function restoreOrder() {
    return dispatch => {
        return dispatch({
            type: orderActions.restoreOrder
        })
    }
}

/**
 * Toggle priority.
 */
export function bringForward(o) {
    return async dispatch => {
        o.priority = !o.priority
        let body = JSON.stringify({priority: o.priority})
        api.prioritizeOrder(o.id, body).then(() => api.getOrders())
            .then(orders => {
                let items = filterContent(orders)
                return dispatch({
                    type: orderActions.getOrders,
                    data: items
                })
            }).catch(() => dispatch({
            type: orderActions.setOffline,
        }))
    }
}

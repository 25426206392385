import React, {useState} from 'react'

import globalStyle from '../assets/styling';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {deleteFilter, setFilter} from "../redux/actions/filters"
import {getOrders} from '../redux/actions/orders';

const checkedIMG = require('../assets/img/stop.png')
const notChecked = require('../assets/img/select.png')

function CheckboxItem(props) {
    const [checked, updateChecked] = useState(props.checked)

    function renderImage() {
        if (checked) {
            return <img style={styles.icon} src={checkedIMG} alt={''}/>
        } else {
            return <img style={styles.icon} src={notChecked} alt={''}/>
        }
    }

    function changeChecked() {
        if (checked) {
            props.changeFilters(props.name, 'add')
        } else {
            props.changeFilters(props.name, 'delete')
        }
        updateChecked(!checked)
    }

    return (
        <div>
            <button style={styles.item} onClick={() => changeChecked()}>
                {renderImage()}
                <div style={styles.text}>{props.name}</div>
            </button>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({setFilter, deleteFilter, getOrders}, dispatch)
const mapStateToProps = (state) => ({
    orders: state.orderReducers.orders
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxItem)

const styles = {
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        paddingLeft: 8
    },
    icon: {
        width: 16,
        height: 16,

    },
    text: {
        color: globalStyle.textColor,
        fontSize: 16,
        marginLeft: 16
    }
}

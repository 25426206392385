import React, {useEffect, useState} from 'react'
import globalStyle from '../assets/styling'
const moment = require('moment-timezone');

export function Timer(props) {
    const [time, updateTime] = useState('')

    function updateTimer() {
        let now = moment.tz('Europe/Amsterdam')
        let orderTime = moment.utc(props.time)
        let duration = moment.duration(now.diff(orderTime))
        let minutesAgo = duration.asMinutes().toFixed(0)
        updateTime(minutesAgo)
    }

    useEffect(() => {
        updateTimer()
        const interval = setInterval(() => {
            updateTimer()
        }, 30000)
        return () => {
            clearInterval(interval)
        }
    }, [props.time])

    function renderTimeStyles() {
        let waitTime = Number(localStorage.getItem('waitTime'))
        if (waitTime) {
            if (time > waitTime) {
                return {...styles.container, backgroundColor: '#FF6B6B'}
            }
        }
        return {
            ...styles.container,
        }

    }

    return (
        <div style={renderTimeStyles()}>
            <p style={styles.p}>{time}</p>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,
        marginBottom: 4,
        padding: 4,
        backgroundColor: globalStyle.secondaryBackgroundColor
    },
    p: {
        margin: 0,
        padding: 4,
        minWidth: 20
    }
}

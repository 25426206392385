const axios = require('axios');
const oauth = require('axios-oauth-client');

export async function hasSettings() {
    const clientSecret = localStorage.getItem('clientSecret')
    const clientId = localStorage.getItem('clientID')
    const apiKey = localStorage.getItem('apiKey')
    if (clientSecret) {
        if (clientId) {
            if (apiKey) {
                return true
            }
        }
    }
    return false
}

export async function saveBearer() {
    const useLocal = localStorage.getItem('useLocal')
    if (useLocal === 'true') {
        return true
    }
    const clientSecret = localStorage.getItem('clientSecret')
    const clientId = localStorage.getItem('clientID')
    const getClientCredentials = oauth.client(axios.create(), {
        url: 'https://povisapi.auth.eu-central-1.amazoncognito.com/oauth2/token',
        grant_type: 'client_credentials',
        client_id: clientId,
        client_secret: clientSecret
    })
    let err = false
    const auth = await getClientCredentials().catch(e => {
        err = true
    })
    if (err) {
        return false
    }
    let now = new Date()
    let expiredTimeStamp = new Date(now.getTime() + auth.expires_in * 1000)
    await localStorage.setItem('tokenExpirationDate', String(expiredTimeStamp))
    await localStorage.setItem('token', auth.access_token)
    return true
} 

const darkmode = (localStorage.getItem('darkMode') === 'true')

const globalStyle = {
    backgroundColor: (darkmode) ? '#030303' : '#F1F2F7',
    textColor: (darkmode) ? '#ffffff' : '#18191A',
    secondaryBackgroundColor: (darkmode) ? '#1A1A1B' : '#FEFFFF',
    fontFamily: 'System',
    accentColor: '#CACDD1',
    secondaryAccentColor: '#3FC5F1',
    button: {
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
    }
}

export default globalStyle

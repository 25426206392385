import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {bringForward, completeOrder, getOrders} from '../redux/actions/orders';

import globalStyle from '../assets/styling'
import {api} from '../utils/api'
import {Product, Timer} from './'

const done = require('../assets/img/done.png')
const danger = require('../assets/img/danger.png')
const fatArrow = require('../assets/img/fat-arrow.png')

function Order(props) {
    useEffect(() => {

    }, [props.order.priority])

    function renderName () {
        if (props.order.name && typeof(props.order.name) === 'string') {
            if(props.order.name.indexOf('<-') !== -1) {
                let names = props.order.name.split('<-')
                return (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', flexWrap: 'wrap'}}>
                        <p style={styles.headerText}>{names[0]}</p>
                        <img src={fatArrow} style={{width: 20, height: 20, marginLeft: 5, marginRight: 5, transform: 'rotate(180deg)'}} />
                        <p style={{...styles.headerText, textDecorationLine: 'line-through'}}> {names[1]}</p>
                    </div>
                )
            }
            return (
                <p style={styles.headerText}>{props.order.name}</p>
            )
        }
    }
    function backgroundColor() {
        if (props.order.continue) {
            return '#bebebe'
        } else if (props.backgroundColor) {
            return props.backgroundColor
        } else {
            return globalStyle.backgroundColor
        }
    }

    async function completeOrder() {
        let newStatus = localStorage.getItem('nextOrder')
        if (newStatus !== 'ready') {
            newStatus = 'completed'
        }
        let body = []
        props.order.orderLines.forEach(order => {
            if (order.complementaryProducts) {
                order.complementaryProducts.forEach(cp => {
                    body.push(
                        {id: cp.id, status: newStatus}
                    )
                })
            }
            body.push(
                {id: order.id, status: newStatus}
            )
        })
        if (props.order.addedLater) {
            props.order.addedLater.forEach(l => {
                if (l.complementaryProducts) {
                    l.complementaryProducts.forEach(cp => {
                        body.push(
                            {id: cp.id, status: newStatus}
                        )
                    })
                }
                body.push(
                    {id: l.id, status: newStatus}
                )
            })
        }
        body = JSON.stringify(body)
        api.updateOrder(props.order.id, body).then(() => {
            props.order.orderLines.forEach(ol => {
                ol.oldStatus = ol.status
            })
            props.completeOrder(props.order)
            props.getOrders()
        })
    }
    const [showStack, updateStack] = useState(props.closed)
    const renderStack = () => {
        let orders = renderOrder()
        let addedLater = renderAddedLater()
        if (addedLater.length > 0) {
            orders.push(
                <div key={(orders.length) ? (orders.length + 1) : 0}>
                    <p style={styles.addedLaterText}>Later toegevoegd:</p>
                    <div style={styles.line}></div>
                    {addedLater}
                </div>
            )
        }
        if (orders.length < 1) {
            return
        }
        if (showStack) {
            return (
                <div style={{...styles.orderContainer, backgroundColor: backgroundColor(), width: props.width, maxHeight: props.height,}}>
                    <div style={styles.header}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <button style={styles.button} onClick={() => updateStack(false)}>
                            {renderName()}
                            </button>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <button style={globalStyle.button} onClick={() => props.bringForward(props.order)}>
                                <Timer time={props.order.createTime}/>
                            </button>
                            <button style={globalStyle.button} onClick={async () => completeOrder()}>
                                <img src={(props.order.priority) ? danger : done} style={{width: 32, height: 32}} alt={''}/>
                            </button>
                        </div>
                    </div>
                    <div style={styles.line}></div>
                    {orders}
                </div>
            )
        } else {
            return (
                <div style={{...styles.closedContainer, backgroundColor: backgroundColor(), width: props.width}}>
                    <div style={styles.header}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <button style={styles.button} onClick={() => updateStack(true)}>
                                {renderName()}
                            </button>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <button style={globalStyle.button} onClick={() => props.bringForward(props.order)}>
                                <Timer time={props.order.createTime}/>
                            </button>
                            <button style={globalStyle.button} onClick={async () => completeOrder()}>
                                <img src={(props.order.priority) ? danger : done} style={{width: 24, height: 24}} alt={'Markeer als gereed'}/>
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    }
    const renderOrder = () => {
        let deviceId = localStorage.getItem('deviceId')
        let renderItems = []
        props.order.orderLines.forEach((o, i) => {
            renderItems.push(
                <Product key={i} product={o} orderId={props.order.id} disabled={(o.screenId !== deviceId)} getOrders={() => props.getOrders()} updateStatusPerProduct={props.updateStatusPerProduct}/>
            )
        })
        return renderItems
    }
    const renderAddedLater = () => {
        let deviceId = localStorage.getItem('deviceId')
        let renderItems = []
        if (props.order.addedLater) {
            props.order.addedLater.forEach((o, i) => {
                renderItems.push(
                    <Product key={i} product={o} orderId={props.order.id} disabled={(o.screenId !== deviceId)} getOrders={() => props.getOrders()} updateStatusPerProduct={props.updateStatusPerProduct}/>
                )
            })
        }
        return renderItems
    }
    return (
        renderStack()
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({completeOrder, bringForward, getOrders}, dispatch)
const mapStateToProps = (state) => ({
    orders: state.orderReducers.orders
})

export default connect(mapStateToProps, mapDispatchToProps)(Order)

const styles = {
    orderContainer: {
        display: 'flex',
        margin: 8,
        overflowY: 'auto',
        boxShadow: "6px 6px 4px 0 rgba(0,0,0,0.13)",
        borderRadius: 5,
        justifyContent: 'flex-start',
        padding: 16,
        flexDirection: 'column',
    },
    shadowProp: {
        shadowOffset: {width: 4, height: 10},
        shadowColor: '#00000',
        shadowOpacity: 0.5,
    },
    button: {
        background: 'none',
        color: 'inherit',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
    },
    closedContainer: {
        display: 'flex',
        margin: 8,
        padding: 16,
        boxShadow: "6px 6px 4px 0 rgba(0,0,0,0.13)",
        borderRadius: 5,
        height: 36,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    headerText: {
        fontSize: 24,
        color: globalStyle.textColor,
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 0
    },
    line: {
        height: 1,
        backgroundColor: globalStyle.textColor
    },
    addedLaterText: {
        margin: 0,
        marginTop: 8,
        color: globalStyle.textColor
    }
} 

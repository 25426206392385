import {orderActions} from "../actions/orders";
import {api} from '../../utils/api'

const initialState = {
    orders: [],
    completedOrders: [],
    restoredOrders: [],
    online: false
}

export default function orderReducers(state = initialState, action) {
    switch (action.type) {
        case orderActions.getOrders:
            return {...state, orders: action.data}
        case orderActions.completeOrder:
            return {...state, orders: state.orders.filter(i => i !== action.data), completedOrders: [...state.completedOrders, action.data]}
        case orderActions.restoreOrder:
            if (!state.completedOrders.at(-1)) {
                return state
            }
            let recalledOrder = state.completedOrders.splice(-1, 1)[0]
            updateOrdersStatus(recalledOrder)
            return {...state, restoredOrders: [recalledOrder, ...state.restoredOrders]}
        // return {...state, orders: [state.completedOrders.splice(-1,1)[0], ...state.orders, ]}
        case orderActions.bringForward:
            let newArray = [state.orders.filter(i => i.id === action.data.id)[0], ...state.orders.filter(i => i.id !== action.data.id)]
            return {...state, orders: newArray}
        case orderActions.setOffline:
            return {...state, online: false}
        case orderActions.setOnline:
            return {...state, online: true}
        default:
            return initialState
    }
}

function updateOrdersStatus(order) {
    let body = []
    order.orderLines.forEach(order => {
        let newStatus
        if (order.oldStatus) {
            newStatus = order.oldStatus
        } else {
            newStatus = 'new'
        }
        body.push(
            {id: order.id, status: newStatus}
        )
    })
    if (order.addedLater) {
        order.addedLater.forEach(l => {
            let newStatus
            if (l.oldStatus) {
                newStatus = l.oldStatus
            } else {
                newStatus = 'new'
            }
            body.push(
                {id: l.id, status: newStatus}
            )
        })
    }
    body = JSON.stringify(body)
    api.updateOrder(order.id, body)
}

import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {saveBearer} from '../utils/auth';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import {getOrders} from "../redux/actions/orders"

function Initializing(props) {
    const navigate = useNavigate();
    useEffect(() => {
        let clientID = localStorage.getItem('clientID')
        let clientSecret = localStorage.getItem('clientSecret')
        let apiKey = localStorage.getItem('apiKey')
        let hostURL = localStorage.getItem('hostURL')
        let useLocal = localStorage.getItem('useLocal')
        let userIdentity = localStorage.getItem('userIdentity')
        let userPass = localStorage.getItem('userPass')
        if (!hostURL) {
            localStorage.setItem('host_url', 'https://api.povis.nl/v1/')
        }

        async function checkLoggedIn() {
            if ((clientSecret && clientID && apiKey) || (useLocal === 'true' && userIdentity && userPass)) {
                let bearer = await saveBearer()
                if (bearer) {
                    props.getOrders()
                    navigate('/home')
                    return
                }
            }
            navigate('/apisettings')
        }

        checkLoggedIn()
    }, [])
    return (
        <div>loading</div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({getOrders}, dispatch)
const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Initializing)

import React, {useEffect, useState} from 'react'
import globalStyle from '../assets/styling'

export const Time = (props) => {
    const [time, updateTime] = useState(new Date())
    useEffect(() => {
        const interval = setInterval(() => {
            updateTime(new Date())
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    function styles() {
        if (props.small) {
            return ({
                fontFamily: globalStyle.fontFamily,
                fontSize: 32,
                color: globalStyle.textColor,
                textAlign: 'left',
                width: 10,
                margin: 0,
                height: 100
            })
        } else {
            return ({
                fontFamily: globalStyle.fontFamily,
                fontSize: 32,
                color: globalStyle.textColor,
                textAlign: 'left',

            })
        }
    }

    function renderTime() {
        if (props.small) {
            return (
                <p style={styles()}>{time.toLocaleTimeString({hour12: false}).split(':')[0] + ' ' + time.toLocaleTimeString().split(':')[1] + ' ' + time.toLocaleTimeString().split(':')[2].slice(0, 2)}</p>
            )
        } else {
            return <p style={styles()}>{time.toLocaleTimeString()}</p>
        }
    }

    return (
        renderTime()
    )
}

const styles = {
    clock: {},
}

export const filterActions = {
    setFilter: '@@filtes/setFilter',
    deleteFilter: '@@filters/deleteFilter'
}

export function setFilter(filter) {
    return dispatch => {
        return dispatch({
            type: filterActions.setFilter,
            data: filter
        })
    }
}

export function deleteFilter(filter) {
    return dispatch => {
        return dispatch({
            type: filterActions.deleteFilter,
            data: filter
        })
    }
}

